.upload-box{
    display: grid;
    text-align: center;
    gap: 20px;
}


.field-file{
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 500px;
    width: 90vw;
    height: 250px;
    margin: 50px auto 0;
}   

.field-file .button{
    margin-top: 15%;
}

input[type="file" i] {
    color: transparent;
    background-color: transparent;
    position: absolute;
    width: 100%;
    height: 74%;
    opacity: 0;
}

.field-file img {
    position: absolute;
    top: 7px;
    right: 7px;
    height: 26px;
    z-index: 10;
}

.files {
    z-index: 10;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    height: 50px;
    margin-top: 30px;
    padding-left: 80px;
    justify-content: space-around;
}

.files::-webkit-scrollbar{
    width: 5px;
}

.file-name-upload{
    width: max-content;
    margin: 0 10px;
    height: 20px;
    position: relative;
}


.file-name-upload button{
    position: absolute;
    background: red;
    color: #fff;
    font-size: 11px;
    padding: 0.15px 4px 2px;
    border-radius: 50%;
    border: none;
    transform: scale(1.2, 1);
    top: 0;
    right: 0;
}